<template>
  <form >
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="">
          <label class="vs-input--label">Old Password</label>
          <vs-input type="password" v-validate="'required|min:4|max:100'" placeholder="Enter old password" name="old_password" v-model="data.old_password" class=" w-full" />
          <span class="text-danger text-sm" v-show="errors.has('old_password')">{{ errors.first('old_password') }}</span>
        </div>
      </div>

      <div class="vx-col w-full">
        <div class="mt-4">
          <label class="vs-input--label">New Password</label>
          <vs-input type="password" ref="password" v-validate="'required|min:4|max:100'" placeholder="Enter new password" name="password" v-model="data.password" class=" w-full" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
      </div>

      <div class="vx-col w-full">
        <div class="mt-4">
          <label class="vs-input--label">Confirm Password</label>
          <vs-input type="password"  v-validate="'required|min:4|max:100|confirmed:password'" data-vv-as="password" placeholder="Confirm Password" name="confirm_password" v-model="data.password_confirmation" class=" w-full" />
          <span class="text-danger text-sm" v-show="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</span>
        </div>
      </div>

      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="filled" :disabled="!validateForm" @click.prevent="updateUser" class="mt-2 block">Update</vs-button>
        </div>
      </div>
    </div>
    </form>
</template>

<script>

export default {
  data() {
    return {
      data: {
        old_password: "",
        password: "",
        password_confirmation: "",
      }
    }
  },
  computed:{
    validateForm() {
      return !this.errors.any()
    },
  },
  methods:{
   updateUser() {
    this.$validator.validateAll().then(result => {
      if (result ) {
        var formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$vs.loading({ color: "#444", type: "sound"})
        this.$http.post(`/account/update-password`, formData)
        .then((response) => {
          this.$vs.loading.close()
          if(response.data.success){
            this.alertSuccess(response.data.success)
            this.resetForm()
          }else if(response.data.errors){
            response.data.errors.forEach((error) => { this.alertError(error)})
          }else{
            this.alertError(response.data.error)
          }
         }).catch((error)=>{console.log(error)})
        }
      })
    },
    resetForm() {
      this.data.old_password = this.data.password = this.data.password_confirmation = "";
      this.$validator.reset()
    },

  },
}
</script>
