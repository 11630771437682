
<template>
  <div id="user-edit-tab-info">
    <form>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <!-- Col Header -->
          <div class="flex items-end">
            <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Personal Information</span>
          </div>
          <!-- Col Content -->
          <div>
              <!-- DOB -->
            <div class="mt-4">
              <label class="text-sm">Birth Date</label>
              <flat-pickr v-model="data.dob" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" placeholder="date of birth" name="dob" />
              <span class="text-danger text-sm"  v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
            </div>
            <div class="mt-4">
              <label class="text-sm">Languages</label>
              <v-select v-model="data.languages" label="name" :reduce="item => item.id" placeholder="--select--" multiple :closeOnSelect="false" :options="languages" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
              <!-- DOB -->
            <div class="mt-4">
              <label class="text-sm">Skills</label>
              <v-select v-model="data.skills" multiple label="name" :reduce="item => item.id"  placeholder="--select--" :closeOnSelect="false" :options="skills" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
            </div>
            <div class="mt-4">
              <label class="text-sm">Country</label>
              <v-select v-model="data.country_id" label="name" v-validate="{ required: true}" :reduce="item => item.id"  name="country" placeholder="--select--" :closeOnSelect="true" :options="countries" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              <span class="text-danger text-sm"  v-show="errors.has('country')">{{ errors.first('country') }}</span>
            </div>
          </div>
        </div>
          <!-- Address Col -->
        <div class="vx-col w-full md:w-1/2">

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="Share2Icon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Social</span>
          </div>
          <!-- Col Content -->
          <div>
            <vs-input
              class="w-full mt-4"
              v-model="data.twitter"
              icon-pack="feather"
              icon="icon-twitter"
              label="Twitter"
              icon-no-border
              placeholder="twitter"
              v-validate="'url:require_protocol'"
              name="twitter" />
              <span class="text-danger text-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>

            <vs-input
              class="w-full mt-4"
              v-model="data.facebook"
              icon-pack="feather"
              icon="icon-facebook"
              label="Facebook"
              icon-no-border
              placeholder="facebook"
              v-validate="'url:require_protocol'"
              name="facebook" />
              <span class="text-danger text-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>

            <vs-input
              class="w-full mt-4"
              v-model="data.instagram"
              icon-pack="feather"
              icon="icon-instagram"
              label="Instagram"
              icon-no-border
              placeholder="instagram"
              v-validate="'url:require_protocol'"
              name="instagram" />
              <span class="text-danger text-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>

            <vs-input
              class="w-full mt-4 "
              v-model="data.github"
              icon-pack="feather"
              icon="icon-github"
              label="GitHub"
              icon-no-border
              placeholder="github"
              v-validate="'url:require_protocol'"
              name="github" />
              <span class="text-danger text-sm"  v-show="errors.has('github')">{{ errors.first('github') }}</span>

          </div>
        </div>

        <div class="vx-col w-full">
           <div class="mt-6">
            <vs-textarea label="About" rows="3" name="about" v-validate="'max:10000'" v-model="data.about" placeholder="Enter bio..." />
            <span class="text-danger text-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
          </div>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-2 flex flex-wrap items-center justify-end">
            <vs-button type="filled" :disabled="!validateForm" @click.prevent="updateUser()" class="mt-2 block">Update</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="patchUser()">Reset</vs-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr
  },
  data() {
    return {
      data:{},
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    countries(){
      return this.$store.state.countries;
    },
    languages(){
      return this.$store.state.languages;
    },
    skills(){
      return this.$store.state.skills;
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$vs.loading({ color: "#444", type: "sound"})
          this.$http.post(`/account/update-info`, formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO',response.data.user)
              this.alertSuccess(response.data.success)
            }else if(response.data.errors){
               response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
              this.alertError(response.data.error)
            }
          }).catch((error)=>{console.log( error)})
        }
      })
    },
    patchUser(){
      this.data = {
        about: this.activeUser.about,
        skills: this.activeUser.skills.map(s => s.id),
        dob: this.activeUser.dob ? new Date(this.activeUser.dob) : null,
        gender: this.activeUser.gender,
        country_id: this.activeUser.country_id,
        languages: this.activeUser.languages.map(l => l.id),
        twitter: this.activeUser.twitter,
        facebook: this.activeUser.facebook,
        instagram: this.activeUser.instagram,
        github: this.activeUser.github,
      }
    },
  },
  created(){
    this.patchUser()
  }
}
</script>
